import { useState } from 'react'
import { type SupporticleInterface } from '@src/dictionaries/commonInterfaces'
import { type Theme } from '@mui/material/styles'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { type AllSupporticlesResponse } from '../../pages/api/rest/supporticles'

interface Props {
  menuData: Record<string, Array<AllSupporticlesResponse['allArticles']>> | Record<string, SupporticleInterface[]>
  isSubcategory?: boolean
  theme: Theme
  key?: string
  libraryEditOpen?: boolean
  panel?: boolean
  setDisplayedArticle: (supporticle: SupporticleInterface) => void
  onClose?: () => void
  fetchSelectedSupporticle?: (slug: string) => Promise<void>
}

const CaseOppMenu = ({
  menuData = {},
  isSubcategory,
  libraryEditOpen,
  panel,
  theme,
  setDisplayedArticle,
  onClose,
  fetchSelectedSupporticle
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const title: string = Object.keys(menuData)[0]

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleArticleSelect = async (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    // Check if event.currentTarget is an HTMLElement
    if (event.currentTarget instanceof HTMLElement) {
      const articleObj = JSON.parse(event.currentTarget.getAttribute('data-value'))
      if (fetchSelectedSupporticle) {
        // This is for the support library, since we only return bare minimum for the All Supporticles fetch
        await fetchSelectedSupporticle(articleObj.slug)
      } else {
        // This is for areas outside the Support libaray
        setDisplayedArticle(articleObj)
      }
    }
  }

  const handleMenuClose = (clickOut?: boolean) => {
    setAnchorEl(null)
    // If selecting an article from a nested subcategory then close both menus
    if (onClose && !clickOut) {
      onClose()
    }
  }

  return (
    <>
      <Button
        color='primary'
        variant='contained'
        sx={{
          height: '55px',
          borderRadius: !isSubcategory ? '5px' : 0,
          width: '325px',
          marginBottom: panel ? '20px' : 'unset',
          marginTop: panel ? '10px' : 'unset',
          position: 'relative',
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.03)'
          },
          '&:focus': {
            transform: 'scale(1)'
          }
        }}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleMenuOpen(event)}
      >
        <Typography style={{ fontSize: 15, fontWeight: 800, paddingRight: 10 }}>{title}</Typography>
        <ArrowDropDownIcon style={{ fontSize: 30, position: 'absolute', right: 15, bottom: 15 }} />
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          handleMenuClose(true)
        }}
        slotProps={{
          paper: {
            sx: {
              backgroundColor: theme?.palette.background.default,
              width: '325px',
              maxHeight: '50vh',
              '& ul': {
                padding: 0
              },
              '& li': {
                whiteSpace: 'normal' // word wrap
              }
            }
          }
        }}
      >
        {!!menuData[title]?.length &&
          menuData[title]?.map((item: any, index: number) => {
            const menuValue = Object.values(item)[0]
            const isNested = Array.isArray(menuValue)

            if (isNested) {
              // If nested then recursively render
              return (
                <CaseOppMenu
                  key={`subcategory${index}`}
                  menuData={item}
                  setDisplayedArticle={setDisplayedArticle}
                  libraryEditOpen={libraryEditOpen}
                  isSubcategory={!isSubcategory}
                  onClose={handleMenuClose}
                  theme={theme}
                  fetchSelectedSupporticle={fetchSelectedSupporticle}
                />
              )
            } else {
              // Otherwise render the dropdown
              return (
                <MenuItem
                  style={{ border: theme?.palette.primary.main, borderTop: 'none', padding: 10, fontSize: 20 }}
                  data-value={JSON.stringify(item)}
                  key={item.articleTitle + index}
                  onClick={(event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                    if (isNested) {
                      // If nested then open the sub menu
                      handleMenuOpen(event as unknown as React.MouseEvent<HTMLButtonElement>)
                    } else {
                      // otherwise display article and close
                      handleArticleSelect(event)
                      handleMenuClose()
                    }
                  }}
                >
                  {item.articleTitle}
                </MenuItem>
              )
            }
          })}
      </Menu>
    </>
  )
}

export default CaseOppMenu
