import HomeIcon from '@mui/icons-material/Home'
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import GroupWorkIcon from '@mui/icons-material/GroupWork'
import HelpIcon from '@mui/icons-material/Help'
import LocalActivityIcon from '@mui/icons-material/LocalActivity'
import InputIcon from '@mui/icons-material/Input'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import CloudIcon from '@mui/icons-material/SettingsSystemDaydream'
import VoicemailIcon from '@mui/icons-material/Voicemail'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import NotificationsIcon from '@mui/icons-material/Notifications'
import TuneIcon from '@mui/icons-material/Tune'
import PhoneCallback from '@mui/icons-material/PhoneCallback'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import TaskAlt from '@mui/icons-material/TaskAlt'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import BabyChangingStationIcon from '@mui/icons-material/BabyChangingStation'
import TtyIcon from '@mui/icons-material/Tty'
import type { NavigationMenuItem } from './commonInterfaces'

/**
 * Exports everything that could be needed to create shortcuts to menu entries.
 * - REQUIRED [page] is set primarily for checking permissions.  If page permission props is different from page file, use route to provide the page filename.
 * - (optional) [read] overrides page to provide a specific string to check against for READ permissions.
 * - (optional) [route] overrides page for the url.    e.g. Home pages uses '/' for url instead of '/home'
 * - (optional) [extraTesting] can provide a boolean result of any number of extra pure expressions.    e.g. !!( process.env.NODE_ENV !== 'test' )
 * - (optional) [emailMustInclude] can provide a string that when defined must be in the users email.      e.g. '@caseopp.com' )
 */
export const navigationMenu: NavigationMenuItem[] = [
  {
    label: 'Home',
    page: 'home',
    route: '',
    icon: <HomeIcon />,
    loadingText: 'home-page-load',
    allowHomePage: true
  },
  {
    label: 'Logout',
    page: 'logout',
    route: 'api/auth/signout',
    icon: <PowerSettingsNewIcon />,
    allowHomePage: false
  },
  {
    label: 'Billing',
    page: 'billing',
    icon: <AttachMoneyIcon />,
    loadingText: 'billing-page-load',
    allowHomePage: true
  },
  {
    label: 'Callback Dashboard',
    page: 'callbackDashboard',
    icon: <PhoneCallback />,
    loadingText: 'callback-dashboard-page-load',
    allowHomePage: true
  },
  {
    label: 'Campaigns',
    page: 'campaigns',
    icon: <QuestionAnswerOutlinedIcon />,
    loadingText: 'campaigns-page-load',
    allowHomePage: true
  },
  {
    label: 'Case Manager Dashboard',
    page: 'caseManagerDashboard',
    icon: <TtyIcon />,
    loadingText: 'case-manager-home-page-load',
    allowHomePage: true
  },
  {
    label: 'Communication Dashboard',
    page: 'communicationDashboard',
    icon: <EmailIcon />,
    loadingText: 'communication-dashboard-load',
    allowHomePage: true
  },
  {
    label: 'Downloads',
    page: 'downloads',
    icon: <CloudIcon />,
    loadingText: 'downloads-page-load',
    allowHomePage: false
  },
  {
    label: 'Event Actions',
    page: 'eventActions',
    icon: <LocalActivityIcon />,
    loadingText: 'event-actions-page-load',
    allowHomePage: true
  },
  {
    label: 'Import / Update Tool',
    page: 'importUpdate',
    icon: <InputIcon />,
    loadingText: 'import-page-load',
    allowHomePage: true
  },
  {
    label: 'Intake Dashboard',
    page: 'intakeDashboard',
    icon: <SupportAgentIcon />,
    loadingText: 'intake-home-page-load',
    allowHomePage: true
  },
  {
    label: 'My Opps Admin',
    page: 'myOpps/admin',
    icon: <AdminPanelSettingsIcon />,
    loadingText: 'my-opps-admin-page-load',
    allowHomePage: true
  },
  {
    label: 'Notification Management',
    page: 'manageNotifications',
    icon: <NotificationsIcon />,
    loadingText: 'notifications-page-load',
    allowHomePage: false
  },
  {
    label: 'Opportunities',
    page: 'opportunities',
    icon: <AssignmentIndIcon />,
    loadingText: 'new-opps-grid-page-load',
    allowHomePage: true
  },
  {
    label: 'Partner Portals',
    page: 'partners',
    read: 'partnerPortal',
    icon: <GroupWorkIcon />,
    loadingText: 'partner-portals-page-load',
    allowHomePage: true
  },
  {
    label: 'Phone',
    page: 'phone',
    icon: <PhoneIcon />,
    loadingText: 'phone-page-load',
    allowHomePage: false
  },
  {
    label: 'Reports',
    page: 'reportEngine',
    icon: <LibraryBooksIcon />,
    loadingText: 'reports-page-load',
    allowHomePage: false
  },
  {
    label: 'Settings',
    page: 'settings',
    icon: <TuneIcon />,
    loadingText: 'settings-page-load',
    allowHomePage: true
  },
  {
    label: 'Supervisor Dashboard',
    page: 'supervisorDashboard',
    icon: <BabyChangingStationIcon />,
    loadingText: 'supervisor-dashboard-page-load',
    allowHomePage: true
  },
  {
    label: 'Support',
    page: 'support',
    read: 'supporticle',
    icon: <HelpIcon />,
    loadingText: 'support-page-load',
    allowHomePage: false
  },
  {
    label: 'Tasks',
    page: 'tasks',
    icon: <TaskAlt />,
    loadingText: 'tasks-page-load',
    allowHomePage: true
  },
  {
    label: 'User Management',
    page: 'users',
    icon: <VpnKeyIcon />,
    loadingText: 'users-page-load',
    allowHomePage: false
  },
  {
    label: 'Voicemails',
    page: 'voicemails',
    icon: <VoicemailIcon />,
    allowHomePage: false
  }
]
