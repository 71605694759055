import {
  arraysShareCommonElements,
  getJsonWithErrorHandling,
  isDateAfter,
  isDateOlderThan,
  variantToBool
} from './helper'
import { type Conditional, type EversignTemplate, type Question, type Snack } from '../dictionaries/commonInterfaces'
import { type ObjectId } from 'mongodb'
import { states } from '@src/dictionaries/dictionaries'
import { apiVercelFallback } from '@src/helpers/clientSide/fetch'
import base64url from 'base64url'

const thisFile = 'newHelper.ts'

/**
 * Filters out empty values from an object.
 * @param obj - The object to filter.
 * @returns The filtered object.
 */
export const filterEmptyValues = <T>(obj: T): T => {
  const newObj = {} as T
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== null && obj[key] !== undefined) {
      newObj[key] = obj[key]
    }
  })
  return newObj
}

/**
 * Converts a string or Date object to a Date object.
 * If the input value is null or an empty string, it returns null.
 * If the input value is already a Date object, it returns the same object.
 * Otherwise, it creates a new Date object from the input value and returns it.
 *
 * @param value - The value to be converted to a Date object.
 * @returns The converted Date object or null if the input value is null or an empty string.
 */
export const returnValueAsDate = (value: string | number | Date | null): Date | null => {
  if (!value || value === null || value === '') {
    return null
  } else if (value instanceof Date) {
    return value
  } else {
    return new Date(value)
  }
}

export interface ConditionalCheckerReturn {
  conditionalsMet: boolean
  unmetConditionals: Array<{ label: string; type: string }>
}

export interface ConditionalResourceValue {
  slug: string
  fieldValue?: string | boolean | number | Date | string[] | undefined
  fieldType?: string
  question?: string | ObjectId
}
/**
 * Checks conditions for a resource to see if they are met.
 * @param resource - The resource to check conditions for.
 * @param values - The values to check against.
 * @returns object, { met:boolean, unmetConditions: string[] }
 */
export const conditionalResourceChecker = (
  resource: Question | EversignTemplate,
  fieldValues: ConditionalResourceValue[],
  flagIds: string[] = []
): ConditionalCheckerReturn => {
  try {
    if (!resource?.conditionals?.length) {
      // Question has no conditionals, question is visible
      return { conditionalsMet: true, unmetConditionals: [] }
    }
    const result = { conditionalsMet: true, unmetConditionals: [] } // Assume conditionals are met, change to false if any conditionals are not met
    const fieldDelimiter = ' | '
    resource.conditionals.forEach((conditional) => {
      const jsonValue = getJsonWithErrorHandling(conditional?.answerValue || conditional?.fieldValue, {
        throwError: false
      })
      const fieldValue: Conditional['answerValue'] | Conditional['fieldValue'] =
        typeof jsonValue !== 'object' ? jsonValue : Array.isArray(jsonValue) ? jsonValue : jsonValue?.text
      const fieldValueToCheck = fieldValues?.find(
        (value: ConditionalResourceValue) =>
          (value?.question && value?.question === conditional?.questionId) ||
          (value?.slug && (value?.slug === conditional?.questionSlug || value?.slug === conditional?.slug))
      )
      switch (conditional.operator) {
        case 'contains':
          // Only used for selects
          if (
            typeof fieldValue === 'string' &&
            !fieldValueToCheck?.fieldValue?.toString()?.split(fieldDelimiter)?.includes(fieldValue)
          ) {
            result.conditionalsMet = false
            result.unmetConditionals.push(conditional)
          } else if (
            !arraysShareCommonElements(
              fieldValueToCheck?.fieldValue?.toString()?.split(fieldDelimiter),
              fieldValue as string[]
            )
          ) {
            result.conditionalsMet = false
            result.unmetConditionals.push(conditional)
          }
          break
        case 'doesNotContain':
          // Only used for selects
          // fieldValue will be string
          if (
            typeof fieldValue === 'string' &&
            fieldValueToCheck?.fieldValue?.toString()?.split(fieldDelimiter)?.includes(fieldValue)
          ) {
            result.conditionalsMet = false
            result.unmetConditionals.push(conditional)
          }
          break
        case 'isAnyOf':
          // Only used for selects
          // fieldValue will be array of strings
          if (
            !arraysShareCommonElements(
              fieldValueToCheck?.fieldValue?.toString()?.split(fieldDelimiter),
              fieldValue as string[]
            )
          ) {
            result.conditionalsMet = false
            result.unmetConditionals.push(conditional)
          }
          break
        case 'isNotAnyOf':
          // Only used for selects
          // fieldValue will be array of strings
          if (
            arraysShareCommonElements(
              fieldValueToCheck?.fieldValue?.toString()?.split(fieldDelimiter),
              fieldValue as string[]
            )
          ) {
            result.conditionalsMet = false
            result.unmetConditionals.push(conditional)
          }
          break
        case 'is':
          // if conditional is of type flag then we will check the condition based on the selected flag
          if (conditional?.type === 'Flag') {
            if (JSON.stringify(flagIds.includes(conditional?.flagId?.toString())) !== fieldValue.toString()) {
              result.conditionalsMet = false
              result.unmetConditionals.push(conditional)
            }
          } else if (fieldValueToCheck?.fieldType === 'Select Many' || fieldValueToCheck?.fieldType === 'Select One') {
            // If questionType is selectMany, answerToCheck.answer will be a string that needs to be split to an array.
            if (
              !(
                fieldValueToCheck?.fieldValue?.toString()?.split(fieldDelimiter)?.toString() === [fieldValue].toString()
              )
            ) {
              result.conditionalsMet = false
              result.unmetConditionals.push(conditional)
            }
          } else if (
            fieldValueToCheck?.fieldType === 'Text Field' &&
            (fieldValueToCheck?.fieldValue as string)?.toLowerCase() !== (fieldValue as string)?.toLowerCase()
          ) {
            result.conditionalsMet = false
            result.unmetConditionals.push(conditional)
          } else if (
            fieldValueToCheck?.fieldType === 'Number' &&
            Number(conditional?.fieldValue) !== Number(fieldValueToCheck?.fieldValue) &&
            Number(conditional?.answerValue) !== Number(fieldValueToCheck?.fieldValue)
          ) {
            result.conditionalsMet = false
            result.unmetConditionals.push(conditional)
          } else if (fieldValueToCheck?.fieldType === 'Boolean') {
            if (variantToBool(fieldValue) !== variantToBool(fieldValueToCheck?.fieldValue)) {
              result.conditionalsMet = false
              result.unmetConditionals.push(conditional)
            }
          } else if (fieldValue && fieldValue !== fieldValueToCheck?.fieldValue) {
            result.conditionalsMet = false
            result.unmetConditionals.push(conditional)
          }
          break
        case 'isNot':
          // if conditional is of type flag then we will check the condition based on the selected flag
          if (
            conditional?.type === 'Flag' &&
            JSON.stringify(flagIds.includes(conditional?.flagId?.toString())) === fieldValue
          ) {
            result.conditionalsMet = false
            result.unmetConditionals.push(conditional)
          } else if (fieldValueToCheck?.fieldType === 'Select Many') {
            // If questionType is selectMany, answerToCheck.answer will be a string that needs to be split to an array.
            if (
              fieldValueToCheck?.fieldValue?.toString()?.split(fieldDelimiter)?.toString() === [fieldValue].toString()
            ) {
              result.conditionalsMet = false
              result.unmetConditionals.push(conditional)
            }
          } else if (fieldValueToCheck?.fieldValue === fieldValue) {
            result.conditionalsMet = false
            result.unmetConditionals.push(conditional)
          }
          break
        case 'isEmpty':
          if (
            fieldValueToCheck?.fieldValue &&
            (fieldValueToCheck?.fieldValue !== '' || fieldValueToCheck?.fieldValue?.toString() !== 'null')
          ) {
            result.conditionalsMet = false
            result.unmetConditionals.push(conditional)
          }
          break
        case 'isNotEmpty':
          if (
            !fieldValueToCheck?.fieldValue ||
            fieldValueToCheck?.fieldValue === '' ||
            fieldValueToCheck?.fieldValue === 'null'
          ) {
            result.conditionalsMet = false
            result.unmetConditionals.push(conditional)
          }
          break
        case 'greaterThan':
          if (fieldValueToCheck?.fieldValue && Number(fieldValueToCheck?.fieldValue) <= Number(fieldValue)) {
            result.conditionalsMet = false
            result.unmetConditionals.push(conditional)
          }
          break
        case 'lessThan':
          if (fieldValueToCheck?.fieldValue && Number(fieldValueToCheck?.fieldValue) >= Number(fieldValue)) {
            result.conditionalsMet = false
            result.unmetConditionals.push(conditional)
          }
          break
        case 'greaterThanOrEqualTo':
          if (fieldValueToCheck?.fieldValue && Number(fieldValueToCheck?.fieldValue) < Number(fieldValue)) {
            result.conditionalsMet = false
            result.unmetConditionals.push(conditional)
          }
          break
        case 'lessThanOrEqualTo':
          if (fieldValueToCheck?.fieldValue && Number(fieldValueToCheck?.fieldValue) > Number(fieldValue)) {
            result.conditionalsMet = false
            result.unmetConditionals.push(conditional)
          }
          break
        case 'isBefore':
          if (
            fieldValueToCheck?.fieldValue &&
            isDateAfter(fieldValueToCheck?.fieldValue?.toString(), fieldValue as string)
          ) {
            result.conditionalsMet = false
            result.unmetConditionals.push(conditional)
          }
          break
        case 'isAfter':
          if (
            fieldValueToCheck?.fieldValue &&
            isDateAfter(fieldValue as string, fieldValueToCheck?.fieldValue?.toString())
          ) {
            result.conditionalsMet = false
            result.unmetConditionals.push(conditional)
          }
          break
        case 'isOlderThan':
          if (
            fieldValueToCheck?.fieldValue &&
            !isDateOlderThan(
              fieldValue[0] as number,
              fieldValue[1] ?? 'days',
              fieldValueToCheck?.fieldValue?.toString()
            )
          ) {
            result.conditionalsMet = false
            result.unmetConditionals.push(conditional)
          }
          break
        case 'isYoungerThan':
          if (
            fieldValueToCheck?.fieldValue &&
            isDateOlderThan(fieldValue[1] as number, fieldValue[0], fieldValueToCheck?.fieldValue?.toString())
          ) {
            result.conditionalsMet = false
            result.unmetConditionals.push(conditional)
          }
          break
        default:
          console.error(thisFile, 'conditionalResourceChecker error: ', 'operator not found')
          break
      }
    })
    return result
  } catch (error) {
    console.error(thisFile, 'conditionalResourceChecker error: ', (error as Error).message)
    return { conditionalsMet: true, unmetConditionals: [] } // If error, assume conditionals are met and return true
  }
}

export function saveQueuedCall<T>(value: T) {
  window?.localStorage?.setItem?.('queuedCall', JSON.stringify(value))
}

export function loadQueuedCall<T>(): T {
  return JSON.parse(window?.localStorage?.getItem?.('queuedCall') || 'null')
}

/**
 * Gets the abbreviation for a given state
 * @param stateToCheck {string} the state name to check (can be string such as `Alabama` or abbreviation `AL`)
 * @returns abbreviation {string} if `stateToCheck` found will return the two letter abbreviation, else will return the passed in `stateToCheck`
 */
export const getAbbreviationFromState = (stateToCheck: string): string => {
  const foundState = states.find(
    (state) =>
      state.name.toLowerCase() === stateToCheck.toLowerCase() ||
      state.abbreviation.toLowerCase() === stateToCheck.toLowerCase()
  )
  return foundState?.abbreviation || stateToCheck
}

/**
 * This function check to see if the flag is used on any Event Actions before being able to be deleted.
 * @param flag - The `flag` to be deleted.
 * @returns Returns an array, the array is empty if there are no conflicts
 */
export const removeFlagsFromOpportunitiesEACheck = async (flag) => {
  const eventActionCheckResponse = await fetch(`/api/rest/eventActionCheck?flagToRemove=${flag}`, {
    method: 'GET'
  })
  if (!eventActionCheckResponse?.ok) {
    const EACheckArray = await eventActionCheckResponse.json()
    return EACheckArray.EAConflictArray
  }
  return true
}

/**
 * This function removes a filter from saved filters and checks for any conflicts with event actions or dashboards.
 * @param filter - The `filter` to be deleted
 * @returns If the 'checkResponse', or everything was successful, it will return an empty array
 * If there are conflicts, it will return an array with the conflicts.
 */
export const removeFiltersFromSavedFiltersEACheck = async (filter: string): Promise<string[]> => {
  const checkResponse = await fetch(`/api/rest/savedFilters?filterToRemove=${filter}`, {
    method: 'DELETE'
  })
  if (!checkResponse?.ok) {
    const conflictArray = await checkResponse.json()
    if (!conflictArray.conflictArray || !Array.isArray(conflictArray.conflictArray)) {
      return []
    }
    return conflictArray.conflictArray
  }
  return []
}

/**
 * This function handles downloading a given document.
 *
 * @param path - The path to the document to be downloaded
 * @param setSnackbar - (Optional) This is used to show a snackbar with an error if the fetch fails
 */
export const handleDownloadDoc = async (path: string, setSnackbar = (param: Snack) => {}) => {
  if (!path) return
  const base64Id = base64url(path)
  const getFile = await apiVercelFallback(`/rest/files/${base64Id}?provider=gcs`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })

  if (!getFile?.file) {
    setSnackbar({ visible: true, severity: 'error', message: 'Error downloading document' })
  } else {
    window.open(getFile.file, '_blank')
  }
}

/**
 * A function to validate email addresses without using Regex.
 * It's a simple function that checks if the email contains invalid characters, spaces, and if it has the correct structure.
 * It tries to complain with all necessary and common RFC rules, without the obscurity of a 500+ chars Regex,
 * also, it's easier to read, understand and modify.
 *
 * Performance-wise, it's not as fast as Regex, but it's fast enough for most use cases thanks to browsers' JS engines optimizations.
 */
export const validateEmail = (email: string): { valid: boolean; message?: string } => {
  if (!email || email.length === 0)
    return {
      valid: false,
      message: 'Email cannot be empty.'
    }

  const allowedChars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  const allowedSymbols = "!#$%&'*+-/=?^_`{|}~@."
  const allowedCharset: string = allowedChars + allowedSymbols
  const whitespaceChars: string[] = [' ', '\t', '\n']

  // Check if the email contain spaces
  if (whitespaceChars.some((char: string) => email.includes(char)))
    return {
      valid: false,
      message: 'Email cannot contain spaces, tabs or new lines.'
    }

  // Check if all chars are allowed
  if (email.split('').some((char: string) => !allowedCharset.includes(char)))
    return {
      valid: false,
      message: 'Email contains invalid characters.'
    }

  // Slice the email into two parts
  const emailSlices: string[] = email.split('@')

  // Check if there is only one @
  if (emailSlices.length !== 2)
    return {
      valid: false,
      message: 'Email must contain exactly one @.'
    }

  // Check if there is at least 1 dot after the @
  if (emailSlices[1].split('.').length < 2)
    return {
      valid: false,
      message: 'Email must contain at least one dot after the @.'
    }

  // Check if there is at least 1 char before the @
  if (emailSlices[0].length < 1)
    return {
      valid: false,
      message: 'Email must contain at least one character before the @.'
    }

  // Check if the first or last char is not a symbol
  if (!allowedChars.includes(emailSlices[0][0]) || !allowedChars.includes(emailSlices[0][emailSlices[0].length - 1]))
    return {
      valid: false,
      message: 'Email cannot start or end with a symbol.'
    }

  // Check if the email contains two dots in a row (This is to cover cases like 'john..doe@something.com' [RFC-952 with no RFC-1123 double quotes validation])
  if (email.includes('..'))
    return {
      valid: false,
      message: 'Email cannot contain two dots in a row.'
    }

  // Check if second part contains symbols aside ".", "_" and "-"
  if (emailSlices[1].split('').some((char: string) => "!#$%&'*+/=?^`{|}~@".includes(char)))
    return {
      valid: false,
      message: 'Email contains invalid characters in the domain part.'
    }

  // Check if second part ends with an allowed symbol
  if ('._-'.includes(emailSlices[1].charAt(emailSlices[1].length - 1)))
    return {
      valid: false,
      message: 'Email domain cannot end with a symbol.'
    }

  // Check if second part starts with an allowed symbol
  if ('._-'.includes(emailSlices[1].charAt(0)))
    return {
      valid: false,
      message: 'Email domain cannot start with a symbol.'
    }

  // Check if there's always 1 char after a dot
  if (emailSlices[1].split('.').some((part: string) => part.length < 1))
    return {
      valid: false,
      message: 'Email domain must contain at least one character after a dot.'
    }

  // If all checks pass, return valid and no message
  return {
    valid: true
  }
}

/**
 * This function checks if a value is actually an object.
 *
 * @param obj - The value to be checked
 */
export const isObject = (obj: unknown) => {
  return typeof obj === 'object' && obj instanceof Object && !Array.isArray(obj) && obj.constructor !== Date
}
