import React, { useState } from 'react'
// MUI Core
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'

import { CloudDownload } from '@mui/icons-material'
import { useQuery } from '@apollo/client'
import { gCSDocDownloadLinkQuery } from '../../../src/gql/googleCloudStorageGQL'
import { consoleError } from '../../../src/helpers/helper'
import IconButton from '@mui/material/IconButton'

/**
 * gets GCS signed URL download link for file, makes linked button for it
 * @param {Object} props
 */
const OppDocumentDownload = (props) => {
  const { fileNameWithPath, fileName, disabled, displayId = null, token = null, fromPartnerPortal = false } = props
  const [urlExpires, setUrlExpires] = useState(Date.now() + 1000 * 60 * 60) // an hour from now
  const { loading, error, data, refetch } = useQuery(gCSDocDownloadLinkQuery, {
    variables: {
      input: {
        fileName: fileNameWithPath,
        displayId,
        token
      }
    },
    context: {
      headers: { authorization: 'Bearer my-super-secret-token', caseoppauth: process.env.CASEOPP_AUTH_TOKEN }
    },
    onCompleted: (data) => {
      // get the expiration from the download url
      const expiresIndex = data?.gCSDocDownloadLink?.indexOf('Expires')
      const expirationDate = data?.gCSDocDownloadLink?.substr(expiresIndex + 8, 10) * 1000 // convert from sec to ms
      setUrlExpires(parseInt(expirationDate))
      if (expirationDate <= Date.now()) {
        // refetch download url if already expired
        if (typeof refetch === 'function') refetch()
      }
    },
    onError: (error) => {
      consoleError('globalSearchError ', error)
    }
  })
  if (loading) return <CircularProgress size={24} color={'inherit'} />
  if (error) return <Typography variant='caption'>Unable to download stored document.</Typography>
  return fromPartnerPortal ? (
    <MenuItem
      onClick={() => {
        window.open(data?.gCSDocDownloadLink, '_blank')
      }}
      disabled={!fileNameWithPath || urlExpires <= Date.now() || disabled}
    >
      Download
    </MenuItem>
  ) : (
    <IconButton
      color='inherit'
      href={data?.gCSDocDownloadLink}
      aria-label='download'
      download={fileName}
      disabled={!fileNameWithPath || (urlExpires && urlExpires <= Date.now()) || disabled}
      target={'_blank'}
    >
      <CloudDownload />
    </IconButton>
  )
}

export default OppDocumentDownload
