/* eslint-disable @next/next/no-img-element */
/* eslint-disable camelcase */
import React, { setGlobal, useEffect, useState } from 'reactn'
// MUI Core
import Toolbar from '@mui/material/Toolbar'
import AppBar from '@mui/material/AppBar'
import Badge from '@mui/material/Badge'
import Menu from '@mui/material/Menu'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import AccountCircle from '@mui/icons-material/AccountCircle'
import BugReport from '@mui/icons-material/BugReport'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import MenuIcon from '@mui/icons-material/Menu'
import Notifications from '@mui/icons-material/Notifications'
import NotificationsOff from '@mui/icons-material/NotificationsOff'
import Tooltip from '@mui/material/Tooltip'
import PersonOutline from '@mui/icons-material/PersonOutline'
import MessageIcon from '@mui/icons-material/Message'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import TroubleshootIcon from '@mui/icons-material/Troubleshoot'
import Select from '@mui/material/Select'
import Hidden from '@mui/material/Hidden'
import * as MUIIcons from '@mui/icons-material'
import useSWR from 'swr'
import GlobalSearch from '../../components/Common/GlobalSearch'
import Switch from '@mui/material/Switch'
import LightMode from '@mui/icons-material/LightMode'
import Brightness3 from '@mui/icons-material/Brightness3'
import GavelIcon from '@mui/icons-material/Gavel'
import Stack from '@mui/material/Stack'
/* eslint-disable no-unused-vars */
import { baseContext, clientWindow, consoleError, getVersion, hasAccess } from '../../src/helpers/helper'
import { apiVercelFallback } from '../../src/helpers/clientSide/fetch'
/* eslint-enable no-unused-vars */
import useLoading from '../../src/hooks/loadingHook'
import UserNotifications from '../Notifications/UserNotifications'
import Support from './Support'
import BugReportDialog from '../Common/BugReportDialog'

import { navigationMenu } from '../../src/dictionaries/dictionariesjsx'
import { signOut } from 'next-auth/react'
import ViewNotificationDialog from '../Notifications/ViewNotificationDialog'

const thisFile = 'Header ' // eslint-disable-line no-unused-vars

function Header(props) {
  // Extract Props
  const { theme, user, headerBoard, router, accessLocation } = props
  const loading = useLoading()
  // Fetch all dashboards
  const { data: allDashboards } = useSWR('/rest/dashboards?isSettings=true', apiVercelFallback, { fallbackData: [] })
  const [open, setOpen] = useState(false)
  const [menuItemSelected, setMenuItemSelected] = useState(null)
  const [anchorElement, setAnchorElement] = useState(null)
  const [notifications, setNotifications] = useState([])
  const [unReadNotificationsCount, setUnReadNotificationsCount] = useState(0)
  const [notificationsAnchorElement, setNotificationsAnchorElement] = useState(null)
  const [themeMode, setThemeMode] = useState(clientWindow?.localStorage.getItem('themeMode') || 'light')
  const [themeToggleDisabled, setThemeToggleDisabled] = useState(false)

  const showLocal = baseContext === 'dev'
  const showStaging = baseContext === 'sta'

  // Handles the auto refresh of the communications
  const { data: communicationsCount } = useSWR(
    `/communications?request=unhandledCommunications&count=true`,
    apiVercelFallback,
    {
      refreshInterval: 60000, // Refresh every 60 seconds
      errorRetryCount: 3,
      onError: (getCommsError) => consoleError(thisFile, 'Get Communications count SWR error, details: ', getCommsError)
    }
  )

  // Gets the users notifications
  const [pollInterval, setPollInterval] = useState(false)
  const [enablePolling, setEnablePolling] = useState(false)
  const { data: notificationsData, mutate: mutateNotifications } = useSWR(
    enablePolling ? `/rest/notifications?userId=${user?.user_id}&isWidget=true` : null,
    apiVercelFallback,
    {
      refreshInterval: pollInterval, // Refresh every 5 minutes
      onError: (error) => {
        setPollInterval(0)
        setEnablePolling(false)
        consoleError(thisFile, 'myNotificationsQuery error: ', error)
      }
    }
  )

  /**
   * sets the notifications state value
   */
  useEffect(() => {
    if (notificationsData && notificationsData?.rows?.length) {
      setNotifications(notificationsData?.rows || [])
      setUnReadNotificationsCount(notificationsData?.metadata?.total)
    }
  }, [notificationsData])

  // set pollInterval to 15 seconds, and enablePolling to true if user.user_id is defined
  useEffect(() => {
    if (user?.user_id) {
      setPollInterval(15000)
      setEnablePolling(true)
    }
  }, [user?.user_id])

  const handleDrawerOpen = () => {
    setOpen(true)
  }
  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleMenuItemClick = (item, index) => {
    loading.loadingStackRemove('ALL')
    if (router?.asPath !== item.route) {
      if (item.route.indexOf('http') > -1) {
        // Detect external url
        clientWindow && clientWindow.open(item.route, '_blank')
      }
      loading.loadingStackPush(item.load)
      setMenuItemSelected(index)
      setGlobal({ loadingMessage: 'Loading ' + item.label + '...' })
      setOpen(false)
    } else {
      handleDrawerClose()
    }
  }

  const handleUserMenu = (event) => {
    setAnchorElement(event.currentTarget)
  }

  const handleUserMenuClose = () => {
    setAnchorElement(null)
  }

  const handleThemeToggle = async () => {
    setThemeMode(themeMode === 'light' ? 'dark' : 'light')
    setThemeToggleDisabled(true)
    clientWindow?.location.reload()
  }

  useEffect(() => {
    clientWindow?.localStorage.setItem('themeMode', themeMode)
    setThemeToggleDisabled(false)
  }, [themeMode])

  // if logged-in user has 'partners' in their app_metadata, push them to their partners
  useEffect(() => {
    if (
      user?.app_metadata?.partners &&
      user?.roles?.length === 1 &&
      user.roles.find((role) => role.name === 'partner')
    ) {
      if (!router.pathname.includes('/partners')) {
        if (user.app_metadata.partners.length === 1) {
          // if there's only one partnerSlug, send them there
          router.push(`/partners/${user.app_metadata.partners[0]}`)
        } else {
          // else send them to /partners to choose a partnerSlug
          router.push('/partners')
        }
      }
    }
  }, [user, router])

  // Setup Menu contents based on if user is logged in or not
  const [menuItems, setMenuItems] = useState([])
  useEffect(() => {
    const menu = []
    if (user) {
      for (const menuItem of navigationMenu) {
        // Handle nested menu item
        if (menuItem.nested) {
          const children = []
          for (const nestedItem of menuItem.children) {
            // if you don't have the permission to see the nested menu item, don't add it to the menu
            if (!hasAccess('page:' + nestedItem.page, user)) continue
            children.push({
              key: nestedItem.page,
              label: nestedItem.label,
              route: '/' + (nestedItem?.route === '' ? '' : nestedItem.route || nestedItem.page),
              load: nestedItem.loadingText,
              icon: nestedItem.icon
            })
          }
          menu.push({ key: menuItem.label, nested: true, label: menuItem.label, icon: menuItem.icon, children })
        } else {
          // if you don't have the permission to see the menu item, don't add it to the menu
          if (
            !hasAccess('page:' + menuItem.page, user) &&
            !hasAccess('page:' + menuItem.read, user) &&
            !['home', 'logout'].includes(menuItem.page)
          )
            continue
          // Use the Cases icon and rename label to Cases if the user is an outsideFirm user
          if (menuItem?.page === 'opportunities' && user?.permissions?.includes('read:onlyViewCases')) {
            menu.push({
              key: menuItem.page,
              label: 'Cases',
              route: '/' + (menuItem?.route === '' ? '' : menuItem.route || menuItem.page),
              load: menuItem.loadingText,
              icon: <GavelIcon />
            })
          } else {
            menu.push({
              key: menuItem.page,
              label: menuItem.label,
              route: '/' + (menuItem?.route === '' ? '' : menuItem.route || menuItem.page),
              load: menuItem.loadingText,
              icon: menuItem.icon
            })
          }
        }
      }
    } else {
      menu.push({ key: 'login', label: 'Login', route: '/api/auth/signin', icon: <AccountCircle /> })
    }
    setMenuItems(menu)
  }, [showLocal, showStaging, user])

  const versionModifier = showLocal
    ? ' LOCAL - ' + process.env.RI_PRODCLONE
    : showStaging
      ? ' STAGING - ' + process.env.RI_PRODCLONE
      : String()

  const DynamicIcon = ({ iconName }) => {
    const Icon = MUIIcons[iconName]
    return <Icon />
  }
  return (
    <>
      <AppBar id='headerAppBar' enableColorOnDark color={showLocal ? 'local' : showStaging ? 'staging' : 'production'}>
        <Toolbar style={{ width: '100%', display: 'flex', justifyContent: 'space-between', height: 65, padding: 0 }}>
          <Stack direction='row' spacing={1} alignItems='center'>
            {props.showMenu &&
              user &&
              user?.permissions?.includes &&
              user.permissions.includes('read:mainSideMenu') && (
                <IconButton
                  disabled={loading.isLoading}
                  color='inherit'
                  aria-label='Open drawer'
                  onClick={handleDrawerOpen}
                >
                  <MenuIcon />
                </IconButton>
              )}
            <Hidden smDown>
              <img alt='header logo' src='/header_logo.png' height='65px' />
            </Hidden>
            <Hidden mdDown>
              <Typography id='version' variant='caption' color='inherit'>
                (v.{getVersion()}) {(showStaging || showLocal) && versionModifier}
              </Typography>
            </Hidden>
          </Stack>
          {user && (
            <>
              <Grid id='headerGrid' item xs={12}>
                {headerBoard}
              </Grid>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Hidden mdDown>{hasAccess('component:globalSearch', user) && <GlobalSearch />}</Hidden>
                <Hidden smDown>{user?.permissions?.includes('create:bug') && <BugReportDialog user={user} />}</Hidden>
                <Hidden smDown>
                  {user?.permissions?.includes('read:supporticle') && <Support user={user} inDrawer router={router} />}
                </Hidden>
                <IconButton
                  id='qa-userMenu'
                  className='qa-userMenu'
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  onClick={handleUserMenu}
                  color='inherit'
                  size='large'
                >
                  <Badge
                    badgeContent={unReadNotificationsCount + (communicationsCount || 0)}
                    max={999}
                    overlap='circular'
                    color={'secondary'}
                    invisible={
                      !user?.permissions?.includes ||
                      !user?.permissions?.includes('read:priorityNewLeads') ||
                      unReadNotificationsCount + communicationsCount === 0
                    }
                  >
                    <img
                      alt='profile picture'
                      src={user?.picture || '/blank-profile-picture-973460_640.png'}
                      style={{ borderRadius: '100%', height: '2em' }}
                    />
                  </Badge>
                </IconButton>
              </Stack>

              <Menu
                id='menu-appbar'
                anchorEl={anchorElement}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                open={Boolean(anchorElement)}
                onClose={handleUserMenuClose}
              >
                <List>
                  <Link underline='hover'>
                    <ListItemButton
                      className='qa-userMenu-logout'
                      aria-label='logout'
                      key='logout'
                      onClick={() => signOut('auth0')}
                    >
                      <ListItemIcon>
                        <img
                          alt='profile picture'
                          src={user?.picture || '/blank-profile-picture-973460_640.png'}
                          style={{ borderRadius: '100%', height: '2em' }}
                        />
                      </ListItemIcon>
                      <ListItemText primary='Logout' sx={{ color: 'primary' }} />
                    </ListItemButton>
                  </Link>
                  {hasAccess('page:myProfile', user) && (
                    <Link href='/myProfile' underline='hover'>
                      <ListItemButton className='qa-userMenu-profile' aria-label='profile' key='myProfileLink'>
                        <ListItemIcon>
                          <PersonOutline />
                        </ListItemIcon>
                        <ListItemText primary='My Profile' />
                      </ListItemButton>
                    </Link>
                  )}
                  {hasAccess('page:myProfile', user) && (
                    <Link href='/myProfile#myStats' underline='hover'>
                      <ListItemButton className='qa-userMenu-myStats' aria-label='myStats' key='myStatsLink'>
                        <ListItemIcon>
                          <DynamicIcon iconName='BarChart' />
                        </ListItemIcon>
                        <ListItemText primary='My Stats' />
                      </ListItemButton>
                    </Link>
                  )}
                  {hasAccess('page:notifications', user) && (
                    <Link underline='hover'>
                      <ListItemButton
                        className='qa-userMenu-notifications'
                        aria-label='notifications'
                        key='notificationsLink'
                        id='notificationIconButton'
                        onClick={(event) => {
                          setNotificationsAnchorElement(event.currentTarget)
                        }}
                      >
                        <ListItemIcon>
                          <Badge badgeContent={unReadNotificationsCount} max={999} color={'secondary'}>
                            {!!notifications.length && <Notifications />}
                            {notifications.length === 0 && <NotificationsOff />}
                          </Badge>
                        </ListItemIcon>
                        <ListItemText primary='Notifications' />
                      </ListItemButton>
                    </Link>
                  )}
                  {hasAccess('page:communicationDashboard', user) && (
                    <Link href='/communicationDashboard' underline='hover'>
                      <ListItemButton
                        className='qa-userMenu-communicationDashboard'
                        aria-label='communicationDashboard'
                        key='communicationDashboard'
                      >
                        <ListItemIcon>
                          <Badge badgeContent={communicationsCount} max={999} color='secondary' invisible={false}>
                            <MessageIcon />
                          </Badge>
                        </ListItemIcon>
                        <ListItemText primary='Unread Communications' />
                      </ListItemButton>
                    </Link>
                  )}
                  {user?.permissions?.includes('create:testBug') && (
                    <Link href='/myProfile' underline='hover'>
                      <ListItemButton className='qa-userMenu-profile' aria-label='profile' key='myProfileLink'>
                        <ListItemIcon>
                          <BugReport />
                        </ListItemIcon>
                        <FormControl>
                          <InputLabel>Bug Generator</InputLabel>
                          <Select value='' style={{ width: '132px' }}>
                            <MenuItem
                              onClick={() => {
                                throw new Error(
                                  'Test-Bug, not a real error, but just so you can see what it looks like.'
                                ) // new test error
                              }}
                            >
                              Test Error
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                console.info(e.something.else)
                              }}
                            >
                              Test Value of Undefined
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                try {
                                  throw new Error(
                                    'An error happened, we knew it could happen and handled it, but it could be an issue somewhere else that needs to be fixed.'
                                  ) // new test error
                                } catch (error) {
                                  consoleError(thisFile, error)
                                }
                              }}
                            >
                              Caught Error
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                consoleError('Good Luck Finding this')
                                for (let index = 0; index < 20; index++) {
                                  console.error(
                                    index,
                                    `Minus harum tempore minus. Eius atque voluptates vero. Est ipsam odit mollitia architecto aliquam necessitatibus. Consequatur atque voluptas laudantium.Autem doloremque non. Odio tenetur sint provident corrupti. Atque itaque assumenda.Doloribus cumque aut sit possimus. Soluta voluptatem sint quia in mollitia id hic odio. Hic harum id at rem. Reiciendis rerum ut et iusto. Est non tempore eaque et reiciendis praesentium eveniet quam eaque.`
                                  )
                                }
                              }}
                            >
                              Lost Error
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </ListItemButton>
                    </Link>
                  )}
                  <Divider />
                  <Link underline='hover'>
                    <ListItemButton
                      className='qa-userMenu-themeToggle'
                      aria-label='themeToggle'
                      key='themeToggle'
                      onClick={handleThemeToggle}
                    >
                      <ListItemIcon>{themeMode === 'light' ? <Brightness3 /> : <LightMode />}</ListItemIcon>
                      <ListItemText primary={themeMode === 'light' ? 'Dark Mode' : 'Light Mode'} />
                      <Switch
                        disabled={themeToggleDisabled}
                        checked={themeMode === 'dark'}
                        onChange={handleThemeToggle}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </ListItemButton>
                  </Link>
                </List>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>

      <br />
      {props.showMenu && (
        <Drawer anchor='left' open={open} onClose={handleDrawerClose}>
          <div>
            <IconButton onClick={handleDrawerClose} size='large'>
              {theme?.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>
          </div>
          <Divider />

          <List>
            {/* Dynamic dashboards */}
            {user?.permissions?.includes('page:customDashboards') && allDashboards?.length > 1 && (
              <FormControl
                fullWidth
                style={{
                  display: 'flex',
                  position: 'relative'
                }}
              >
                <ListItemButton style={{ position: 'absolute', top: '5px' }}>
                  <ListItemIcon>
                    <TroubleshootIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Dashboards'} sx={(theme) => ({ color: theme.palette.text.primary })} />
                </ListItemButton>
                <Select
                  value={menuItemSelected || ''}
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                    '&:hover': { backgroundColor: 'rgb(0, 0, 0, 0.05)' }
                  }}
                >
                  {!!allDashboards?.length &&
                    allDashboards.map((child, index) => {
                      if (child.steps?.length) {
                        return (
                          <Link key={child.name + index} href={`/dashboards/${child.slug}`} underline='hover'>
                            <Tooltip placement='right' title={child.description}>
                              <ListItemButton
                                selected={menuItemSelected === index}
                                onClick={() => {
                                  handleDrawerClose()
                                  loading.loadingStackPush('Dashboard Loading')
                                }}
                              >
                                {child.icon && (
                                  <ListItemIcon>
                                    <DynamicIcon iconName={child.icon} />
                                  </ListItemIcon>
                                )}
                                <ListItemText
                                  primary={child.name}
                                  sx={(theme) => ({ color: theme.palette.text.primary })}
                                />
                              </ListItemButton>
                            </Tooltip>
                          </Link>
                        )
                      } else {
                        return null
                      }
                    })}
                </Select>
              </FormControl>
            )}
            {allDashboards?.length === 1 && (
              // Button for the only dashboard
              <Link href={`/dashboards/${allDashboards[0].slug}`} underline='hover'>
                <ListItemButton
                  selected={menuItemSelected === 0}
                  onClick={() => {
                    handleDrawerClose()
                    loading.loadingStackPush('Dashboard Loading')
                  }}
                >
                  {allDashboards[0].icon && (
                    <ListItemIcon>
                      <DynamicIcon iconName={allDashboards[0].icon} />
                    </ListItemIcon>
                  )}
                  <ListItemText
                    primary={allDashboards[0].name}
                    sx={(theme) => ({ color: theme.palette.text.primary })}
                  />
                </ListItemButton>
              </Link>
            )}
            {/* Navigation menu items */}
            {menuItems?.map((item, index) => {
              return (
                <Link href={`${item.route}`} underline='hover' key={item.key}>
                  <ListItemButton
                    selected={menuItemSelected === index}
                    onClick={() => handleMenuItemClick(item, index)}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} sx={(theme) => ({ color: theme.palette.text.primary })} />
                  </ListItemButton>
                </Link>
              )
            })}
          </List>
        </Drawer>
      )}
      {user && !loading.isLoading && (!!notificationsAnchorElement || notifications?.length) && (
        <Drawer
          anchor='right'
          onClose={() => setNotificationsAnchorElement(null)}
          open={!!notificationsAnchorElement}
          disableEnforceFocus
        >
          <div style={{ width: '80vw' }}>
            <div>
              <IconButton
                onClick={(event) => {
                  event.preventDefault()
                  setNotificationsAnchorElement(null)
                  user && mutateNotifications()
                }}
                size='large'
              >
                <ChevronRight />
                Notifications
              </IconButton>
            </div>
            <Divider />
            {user && <UserNotifications user={user} isWidget />}
          </div>
        </Drawer>
      )}
      <ViewNotificationDialog user={user} accessLocation={accessLocation} fetchNotifications />
    </>
  )
}

export default Header
